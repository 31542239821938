import React from 'react';
import './CssStyles/EducationHosp.css';

function EducationHosp() {
    return (
        <div className='education-hosp'>
            <div className='left-div'>
                <img src='/Images/cresmond.png' alt='Education' className='background-image' />
                <a href='https://cresmonde.com/' target='_blank' rel='noopener noreferrer' className='no-decoration'>
                    <h1>Education Endeavour</h1>
                </a>
            </div>
            <div className='right-div'>
                <img src='/Images/byke.png' alt='Hospitality' className='background-image' />
                <a href='https://thebyke.com/hotels/the-byke-royal-pearl-north-goa/' target='_blank' rel='noopener noreferrer' className='no-decoration'>
                    <h1>Hospitality Endeavour</h1>
                </a>
            </div>
        </div>
    )
}

export default EducationHosp;