import React from 'react';
import './CssStyles/AboutUs.css';

function AboutUs() {
    return (
        <div className="about-page">
            <h1 className="about-title">Welcome to Gagangiri...</h1>
            <p className="about-text">Gagangiri Construction is the development arm of Ellora group, which has been at the forefront of creating high-quality residential developments, alongside planning & managing a number of premium residential / commercial, redevelopment & infrastructure projects. At Gagangiri Construction, we believe in more than just building homes — we build communities that reflect the aspirations and lifestyles of modern families.</p>
            <p className='about-text'>What sets us apart is the in-house multidisciplinary team of architects, engineers, liaisons, and designers, who work together to ensure seamless project execution from initiation to completion. This integrated approach allows us to maintain high standards of craftsmanship and on-time project delivery, while also ensuring that every aspect of design and functionality is meticulously planned and executed.</p>
        </div>
    );
}

export default AboutUs;