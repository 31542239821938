let googleMapsPromise;

const loadGoogleMaps = (apiKey) => {
    if (!googleMapsPromise) {
        googleMapsPromise = new Promise((resolve, reject) => {
            if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
                resolve(window.google.maps);
                return;
            }

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
            script.async = true;
            script.defer = true;
            script.onerror = reject;
            document.head.appendChild(script);

            window.initMap = () => {
                resolve(window.google.maps);
            };
        });
    }

    return googleMapsPromise;
};

export default loadGoogleMaps;