import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faLocationDot, faCar, faTimes } from '@fortawesome/free-solid-svg-icons';
import projects from './ProjectsJSON/project-information.json';
import ContactUs from './ContactUs.js';
import MapComponent from './MapComponent';
import Footer from './Footer.js';
import './CssStyles/ProjectDetails.css';

function ProjectDetails() {
    const { id } = useParams();
    const project = projects.find(proj => proj['project-id'] === id);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentFloorPlanIndex, setCurrentFloorPlanIndex] = useState(0);
    const [expandedAmenities, setExpandedAmenities] = useState({});
    const descriptionRefs = useRef([]);
    const [isImageDetailsActive, setIsImageDetailsActive] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isFloorPlanDetailsActive, setIsFloorPlanDetailsActive] = useState(true);
    const [isFloorPlanAnimating, setIsFloorPlanAnimating] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // useEffect(() => {
    //     if (project) {
    //         console.log('Project Details:', project);
    //     }
    // }, [project]);

    useEffect(() => {
        setIsImageDetailsActive(false);
        const timer = setTimeout(() => setIsImageDetailsActive(true), 50); // Apply active class after a short delay
        return () => clearTimeout(timer);
    }, [currentImageIndex]);

    useEffect(() => {
        setIsAnimating(true);
        const timer = setTimeout(() => setIsAnimating(false), 500); // Duration of the animation
        return () => clearTimeout(timer);
    }, [currentImageIndex]);

    useEffect(() => {
        setIsFloorPlanDetailsActive(false);
        const timer = setTimeout(() => setIsFloorPlanDetailsActive(true), 50); // Apply active class after a short delay
        return () => clearTimeout(timer);
    }, [currentFloorPlanIndex]);

    useEffect(() => {
        setIsFloorPlanAnimating(true);
        const timer = setTimeout(() => setIsFloorPlanAnimating(false), 500); // Duration of the animation
        return () => clearTimeout(timer);
    }, [currentFloorPlanIndex]);

    if (!project) {
        return <div>Project not found</div>;
    }

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % project['project-images'].length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + project['project-images'].length) % project['project-images'].length);
    };

    const handleNextFloorPlan = () => {
        setCurrentFloorPlanIndex((prevIndex) => (prevIndex + 1) % project['floor-plans'].length);
    };

    const handlePrevFloorPlan = () => {
        setCurrentFloorPlanIndex((prevIndex) => (prevIndex - 1 + project['floor-plans'].length) % project['floor-plans'].length);
    };

    const toggleAmenity = (index) => {
        setExpandedAmenities((prevState) => {
            const newState = { ...prevState, [index]: !prevState[index] };

            // Dynamically set height based on content
            setTimeout(() => {
                const element = descriptionRefs.current[index];
                if (element) {
                    if (newState[index]) {
                        element.style.height = `${element.scrollHeight + 10}px`;
                    } else {
                        element.style.height = '0';
                    }
                }
            }, 0);

            return newState;
        });
    };

    const handleEnquireClick = () => {
        const contactUsElement = document.getElementById('contact-us');
        if (contactUsElement) {
            contactUsElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='project-details'>
            <div className='project-main-image'>
                <img
                    src={project['project-page-main-image']}
                    alt={project['project-name']}
                    className='project-main-image-img'
                />
            </div>
            <button className='enquire-button' onClick={handleEnquireClick}>Enquire now</button>
            <div className='project-info'>
                <div className='row'>
                    <div className='project-name'>
                        {project['project-name']}
                        <div className='maha-rera'>
                            <div className='label-project-name-rera'>MAHA Rera no.</div>
                            <div className='info-project-name-rera'>{project.reraNo}</div>
                        </div>
                    </div>
                    <div className='project-description'>{project['project-description']}</div>
                </div>
                <div className='row'>
                    <div className='info-block'>
                        <div className='label'>Location</div>
                        <div className='info'>{project.location}</div>
                    </div>
                    <div className='info-block'>
                        <div className='label'>Status</div>
                        <div className='info'>{project.status}</div>
                    </div>
                    <div className='info-block'>
                        <div className='label'>Configuration</div>
                        <div className='info'>{project.configuration}</div>
                    </div>
                </div>
                <div className='row'>
                    <div className='info-block'>
                        <div className='label'>Price</div>
                        <div className='info'>{project.price}</div>
                    </div>
                    <div className='info-block'>
                        <div className='label'>Area</div>
                        <div className='info'>{project.area}</div>
                    </div>
                    <div className='info-block'>
                        <div className='label'>Year Completion</div>
                        <div className='info'>{project['year-completion']}</div>
                    </div>
                </div>
            </div>
            <div className='image-slider'>
                <FontAwesomeIcon icon={faCaretLeft} onClick={handlePrevImage} className='slider-icon left' />
                <div className='slider-card'>
                    {project['project-images'].map((image, index) => (
                        <div key={index} className={`slider-image-container ${index === currentImageIndex ? 'active' : ''}`}>
                            <img
                                src={image.path}
                                alt={image.title}
                                className={`slider-image ${index === currentImageIndex ? 'active' : ''}`}
                            />
                        </div>
                    ))}
                    <div className='image-indicator'>
                        {project['project-images'].map((_, idx) => (
                            <span key={idx} className={`indicator-dot ${idx === currentImageIndex ? 'active' : ''}`}></span>
                        ))}
                    </div>
                </div>
                <FontAwesomeIcon icon={faCaretRight} onClick={handleNextImage} className='slider-icon right' />
            </div>
            <div className={`image-details ${isImageDetailsActive ? 'active' : ''}`}>
                <div className={`image-text ${isAnimating ? 'animating' : 'active'}`}>
                    {project['project-images'][currentImageIndex].title}
                </div>
                <div className={`image-description ${isAnimating ? 'animating' : 'active'}`}>
                    {project['project-images'][currentImageIndex].description}
                </div>
            </div>
            {project['project-amenities'] && project['project-amenities'].length > 0 && (
                <div className='amenities-section'>
                    <h2 className='amenities-title'>Amenities</h2>
                    <div className='amenities-list'>
                        {project['project-amenities'].map((amenity, index) => (
                            <div key={index} className={`amenity-row ${expandedAmenities[index] ? 'expanded' : ''}`}>
                                <div className='amenity-header' onClick={() => toggleAmenity(index)}>
                                    <span className='amenity-text'>{amenity.amenity}</span>
                                </div>
                                <div className='amenity-description' ref={el => descriptionRefs.current[index] = el}>
                                    {amenity.description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className='location-section'>
                <div className='location-left'>
                    <h2 className='location-title'>Explore the location</h2>
                    <div
                        className='location-row'
                    >
                        <span className='location-name'>
                            <FontAwesomeIcon icon={faLocationDot} /> {project['project-name']}
                        </span>
                    </div>
                    <div className='location-headings'>
                        <span className='location-heading'>Location</span>
                        <span className='duration-heading'>Duration</span>
                    </div>
                    {project['location-details'].details.map((detail, index) => (
                        <div
                            key={index}
                            className='location-row'
                        >
                            <span className='location-name'>
                                <FontAwesomeIcon icon={faLocationDot} /> {detail['location-name']}
                            </span>
                            <span className='time-to-location'>
                                <FontAwesomeIcon icon={faCar} /> {detail['time-to-location']}
                            </span>
                        </div>
                    ))}
                </div>
                <div className='location-right'>
                    <MapComponent project={project} />
                </div>
            </div>
            {project['floor-plans'] && project['floor-plans'].length > 0 && (
                <div className='floor-plans-section'>
                    <h2 className='floor-plans-title'>Floor Plans</h2>
                    <div className='image-slider'>
                        <FontAwesomeIcon icon={faCaretLeft} onClick={handlePrevFloorPlan} className='slider-icon left' />
                        <div className='slider-card'>
                            {project['floor-plans'].map((image, index) => (
                                <div key={index} className={`slider-image-container ${index === currentFloorPlanIndex ? 'active' : ''}`}>
                                    <img
                                        src={image.path}
                                        alt={image.title}
                                        className={`slider-image ${index === currentFloorPlanIndex ? 'active' : ''}`}
                                    />
                                </div>
                            ))}
                            <div className='image-indicator'>
                                {project['floor-plans'].map((_, idx) => (
                                    <span key={idx} className={`indicator-dot ${idx === currentFloorPlanIndex ? 'active' : ''}`}></span>
                                ))}
                            </div>
                        </div>
                        <FontAwesomeIcon icon={faCaretRight} onClick={handleNextFloorPlan} className='slider-icon right' />
                    </div>
                    <div className={`image-details ${isFloorPlanDetailsActive ? 'active' : ''}`}>
                        <div className={`image-text ${isFloorPlanAnimating ? 'animating' : 'active'}`}>
                            {project['floor-plans'][currentFloorPlanIndex].title}
                        </div>
                        <div className={`image-description ${isFloorPlanAnimating ? 'animating' : 'active'}`}>
                            {project['floor-plans'][currentFloorPlanIndex].description}
                        </div>
                    </div>
                </div>
            )}
            <div className='project-details-contact-us'>
                <ContactUs project={project} />
            </div>
            <div className='disclaimer-section'>
                <button className="disclaimer-button" onClick={openModal}>
                    Disclaimer
                </button>
                {isModalOpen && (
                    <div className="modal" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <span className="close" onClick={closeModal}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                            <div className="disclaimer-section-content">
                                The images, appearances, colours, etc. given herein are mere artistic impressions for representation purposes only and do not constitute an offer, an invitation to offer and/or commitment of any nature between the promoter and the recipient. The data/information herein is intended to give a general understanding of the subject matter and is subject to change without any prior notice. Readers are therefore requested to verify all details, including area, amenities, services, terms of sale and payment schedule and other relevant terms independently with the promoter prior to arriving at any decision of buying any apartment/plot in the said project. The binding offering shall be governed by the terms and conditions of the Agreement for Sale only. In no event will the Promoter be liable for any claim made by the reader including seeking any cancellation and or/withdrawal for any of the inaccuracies in the information provided in the advertisement, though all the efforts have been made to ensure accuracy. We also do not hold any responsibility for any information provided by any broker/channel partner/property dealer or made available on any website/email communication other than official website/email/correspondence. [1 sq. mt. = 10.764 sq. ft. , 1 sq. mt. = 1.196 sq. yd.]. *T&C apply.
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default ProjectDetails;