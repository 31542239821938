import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './CssStyles/MahaSection.css';

function MahaSection() {
    return (
        <div className="maha-section">
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Project Description</th>
                        <th>Stamp Duty Paper</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Facility for reduction in premium as per G.R. dtd.14.01.2021 availed by Society / Developer for Proposed redevelopment of "Nehru Nagar The Ulhas Co-op. Housing Society Limited", for redevelopment project of its property by constructing new building known as "Gagan 139", on plot bearing CTS no. 2(pt.) of Village Kurla - II situated at Nehru Nagar, Kurla (East), Mumbai 400024.</td>
                        <td>
                            <a href="/Images/gagan139stampduty.pdf" download>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Facility for reduction in premium as per G.R dtd.14.01.2021 availed by Society / Developers for proposed redevelopment of “Sarita Vidya Mahal CHS LTD, Nehru Nagar”, for redevelopment project of its property by constructing new building known as “Gagan138”, on plot bearing CTS no. 2(pt.) of Village Kurla - II situated at Nehru Nagar, Kurla (East), Mumbai - 400024.</td>
                        <td>
                            <a href="/Images/gagan138stampduty.pdf" download>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Facility for reduction in premium as per G.R dtd.14.01.2021 availed by Society / Developers for proposed redevelopment of “Kedar Darshan CHS LTD, Nehru Nagar”, for redevelopment project of its property by constructing new building known as “Gagan45”, on plot bearing CTS no. 2(pt.) of Village Kurla - II situated at Nehru Nagar, Kurla (East), Mumbai - 400024.</td>
                        <td>
                            <a href="/Images/gagan45stampduty.pdf" download>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default MahaSection;