import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar.js';
import LandingPage from './Components/LandingPage.js';
import AboutUs from './Components/AboutUs.js';
import CompanyProfile from './Components/CompanyProfile.js';
import OurPhilosophy from './Components/OurPhilosophy.js';
import ProjectsOverview from './Components/ProjectsOverview.js';
import ContactUs from './Components/ContactUs.js';
import ExecutiveProfile from './Components/ExecutiveProfile.js';
import FeaturedProjects from './Components/FeaturedProjects.js';
import ProjectDetails from './Components/ProjectDetails.js';
import ScrollToTop from './Components/ScrollToTop.js';
import EducationHosp from './Components/EducationHosp.js';
import MahaSection from './Components/MahaSection.js';
import Footer from './Components/Footer.js';

function ScrollToSection() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <ScrollToTop />
        <ScrollToSection />
        <Routes>
          <Route path="/" element={
            <>
              <LandingPage />
              <AboutUs />
              <div id="company-profile">
                <CompanyProfile />
              </div>
              <div id="our-philosophy">
                <OurPhilosophy />
              </div>
              <div id="projects-overview">
                <ProjectsOverview />
              </div>
              <EducationHosp />
              <div id="contact-us-app">
                <ContactUs />
              </div>
              <MahaSection />
              <Footer />
            </>
          } />
          <Route path="/executive-profile" element={<ExecutiveProfile />} />
          <Route path="/featured-projects" element={<FeaturedProjects />} />
          <Route path="/project/:id" element={<ProjectDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;