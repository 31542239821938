import React from 'react';
import './CssStyles/OurPhilosophy.css';

function OurPhilosophy() {
    return (
        <div className="philosophy-page">
            <h1 className="philosophy-title">Our Philosophy</h1>
            <div className="philosophy-subtitle-container">
                <h2 className="philosophy-subtitle">A Commitment to Excellence</h2>
            </div>
            <p className="philosophy-text">With over 30 years of experience in the real estate sector, Gagangiri Construction with Ellora Group remain committed to delivering prowess in every project. By combining consultancy expertise with hands-on project management, we create developments with benchmarks for quality, design and timeliness. We work closely with all stakeholders to ensure every project reflects our core values of integrity & transparency.</p>
        </div>
    );
}

export default OurPhilosophy;