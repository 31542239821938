import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './CssStyles/ProjectsOverview.css';

function ProjectsOverview() {
    const [count, setCount] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const sectionRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    startCounter();
                    setHasAnimated(true);
                }
            },
            { threshold: 0.5 } // Adjust this value as needed
        );

        const currentRef = sectionRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [hasAnimated]);

    const startCounter = () => {
        let start = 0;
        const end = 25;
        const duration = 1000; // Duration of the animation in milliseconds
        const incrementTime = 50; // Time between each increment in milliseconds
        const step = (end - start) / (duration / incrementTime);

        const counter = setInterval(() => {
            start += step;
            if (start >= end) {
                start = end;
                clearInterval(counter);
            }
            setCount(Math.ceil(start));
        }, incrementTime);
    };

    const handleButtonClick = (status) => {
        navigate(`/featured-projects?status=${status}`);
    };

    return (
        <div className='projects-overview' ref={sectionRef}>
            <h1 className='po-title'>Our Projects</h1>
            <div className='po-rect-container'>
                <div className='po-rect1'>
                    <p className='po-text-large-1'>{count}+</p>
                    <p className='po-text-small-1'>Completed, Upcoming, and Ongoing projects</p>
                </div>
            </div>
            <p className='po-below-rect-text'>With over 25 completed, ongoing, and upcoming projects, Gagangiri Constructions has a strong presence across Mumbai’s most sought-after residential areas and across western India. Whether it’s the redevelopment of existing properties or the creation of brand-new residential complexes, each project is a reflection of our commitment.</p>
            <div className='po-button-container'>
                <button className='po-center-button' onClick={() => handleButtonClick('Completed')}>
                    <span>View Completed Projects</span>
                </button>
                <button className='po-center-button' onClick={() => handleButtonClick('Ongoing')}>
                    <span>View Ongoing Projects</span>
                </button>
                <button className='po-center-button' onClick={() => handleButtonClick('Upcoming')}>
                    <span>View Upcoming Projects</span>
                </button>
            </div>
        </div>
    );
}

export default ProjectsOverview;