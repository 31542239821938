/* global google */
import React, { useEffect, useRef } from 'react';
import loadGoogleMaps from './loadGoogleMaps';

const MapComponent = ({ project }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        if (!apiKey) {
            console.error("Google Maps API key is missing");
            return;
        }

        const projectLocation = {
            lat: project['location-details'].latitude,
            lng: project['location-details'].longitude
        };

        const projectAddress = project['location-details'].address;

        loadGoogleMaps(apiKey).then((googleMaps) => {
            if (mapRef.current) {
                const map = new googleMaps.Map(mapRef.current, {
                    center: projectLocation,
                    zoom: 13,
                });

                // Custom icon for the project location marker
                const customIcon = {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(`
                        <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#061437" d="M24 2C17.37 2 12 7.37 12 14c0 10.5 12 26 12 26s12-15.5 12-26c0-6.63-5.37-12-12-12zm0 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z"/>
                        </svg>
                    `),
                    scaledSize: new googleMaps.Size(48, 48),
                    anchor: new googleMaps.Point(24, 48),
                };

                // Project location marker
                const projectMarker = new googleMaps.Marker({
                    position: projectLocation,
                    map,
                    title: project['project-name'],
                    icon: customIcon,
                });

                const projectInfoWindow = new googleMaps.InfoWindow({
                    content: `
                        <div>
                            <strong>${project['project-name']}</strong><br/>
                            ${projectAddress}
                        </div>
                    `,
                });

                projectMarker.addListener('click', () => {
                    projectInfoWindow.open(map, projectMarker);
                });

                // Nearby locations markers
                project['location-details'].details.forEach((detail) => {
                    const location = {
                        lat: detail.latitude,
                        lng: detail.longitude
                    };

                    const marker = new googleMaps.Marker({
                        position: location,
                        map,
                        title: detail['location-name'],
                    });

                    const infoWindow = new googleMaps.InfoWindow({
                        content: `<div><strong>${detail['location-name']}</strong></div>`,
                    });

                    marker.addListener('click', () => {
                        infoWindow.open(map, marker);
                    });
                });

                // Handle map resize
                googleMaps.event.addDomListener(window, 'resize', () => {
                    googleMaps.event.trigger(map, 'resize');
                    map.setCenter(projectLocation);
                });
            }
        }).catch((error) => {
            console.error("Error loading Google Maps:", error);
        });
    }, [project]);

    return <div id="map" ref={mapRef} style={{ width: '100%', height: '70vh' }}></div>;
};

export default MapComponent;