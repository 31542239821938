import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CssStyles/CompanyProfile.css';

function CompanyProfile() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        console.log('View Executive Profile button clicked');
        navigate('/executive-profile');
    };

    return (
        <div className='company-profile'>
            <h1 className='cp-title'>Company Profile</h1>
            <div className='cp-rect-container'>
                <div className='cp-rect rect1'>
                    <p className='cp-text-large-1'>4M+</p>
                    <p className='cp-text-small-1'>Gross Square Feet of Past, Current, and Future projects</p>
                </div>
                <div className='cp-rect rect2'>
                    <p className='cp-text-large-2'>2021</p>
                    <p className='cp-text-small-2'>Year Founded</p>
                </div>
            </div>
            <p className='cp-below-rect-text'>Gagangiri Construction has successfully delivered numerous residential projects, each embodying our philosophy of enhancing the quality of life for the residents. From residential apartments to community-centric developments, our projects are known for their attention to detail, durability, and superior finishes, along with fast-pace construction. Backed by over 30 years of experience and a dedicated team of professionals, we continue to shape the future of residential living with a passion for excellence and a commitment to creating spaces that inspire.</p>
            <button className='cp-center-button' onClick={handleButtonClick}><span>View Executive Profile</span></button>
        </div>
    );
}

export default CompanyProfile;