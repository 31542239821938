import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './CssStyles/NavBar.css';

function NavBar() {
    const [aboutDropdown, setAboutDropdown] = useState(false);
    const [projectsDropdown, setProjectsDropdown] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [animationComplete, setAnimationComplete] = useState(false);
    const navigate = useNavigate();

    const toggleAboutDropdown = () => setAboutDropdown(!aboutDropdown);
    const toggleProjectsDropdown = () => setProjectsDropdown(!projectsDropdown);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
        console.log('Mobile menu open:', !mobileMenuOpen); // Debugging statement
    };
    const closeMobileMenu = () => {
        handleNavigation('/#contact-us')
        setMobileMenuOpen(false)
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setAnimationComplete(true);
        }, 2000); // Match the duration of the slide-in animation

        return () => clearTimeout(timer);
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
        setMobileMenuOpen(false);
    };

    return (
        <>
            <nav className='navbar'>
                {animationComplete && (
                    <div className='navbar-logo'>
                        <Link to='/'><img src='/Images/GaganGiriLogoNoBgResized.png' alt='Logo' /></Link>
                        <Link to='/' className='navbar-text-link'><div className='navbar-text'>GAGANGIRI GROUP</div></Link>
                    </div>
                )}
                {animationComplete && (
                    <>
                        <div className={`navbar-links ${mobileMenuOpen ? 'show' : 'animate'}`}>
                            <div className='nav-link' onClick={toggleAboutDropdown}>
                                ABOUT US <FontAwesomeIcon icon={faAngleDown} className={aboutDropdown ? 'rotate' : ''} />
                                <div className={`dropdown ${aboutDropdown ? 'show' : ''}`}>
                                    <Link to="/#company-profile" onClick={() => handleNavigation('/#company-profile')}>Company Profile</Link>
                                    <Link to='/executive-profile' onClick={closeMobileMenu}>Executive Profile</Link>
                                </div>
                            </div>
                            <Link to="/#our-philosophy" onClick={() => handleNavigation('/#our-philosophy')}>OUR PHILOSOPHY</Link>
                            <div className='nav-link' onClick={toggleProjectsDropdown}>
                                PROJECTS <FontAwesomeIcon icon={faAngleDown} className={projectsDropdown ? 'rotate' : ''} />
                                <div className={`dropdown ${projectsDropdown ? 'show' : ''}`}>
                                    <Link to="/#projects-overview" onClick={() => handleNavigation('/#projects-overview')}>Projects Overview</Link>
                                    <Link to='/featured-projects' onClick={closeMobileMenu}>All Projects</Link>
                                    <Link to='/featured-projects?status=Ongoing' onClick={closeMobileMenu}>Ongoing Projects</Link>
                                    <Link to='/featured-projects?status=Completed' onClick={closeMobileMenu}>Completed Projects</Link>
                                    <Link to='/featured-projects?status=Upcoming' onClick={closeMobileMenu}>Upcoming Projects</Link>
                                </div>
                            </div>
                            {mobileMenuOpen && (
                                <>
                                    <button className='contact-button' onClick={closeMobileMenu}><span>CONTACT US</span></button>
                                    <div className='social-icons'>
                                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </a>
                                        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faXTwitter} />
                                        </a>
                                        <a href="https://www.instagram.com/gagangirigroup/?igsh=N3ExMTFzN3B2Zjlh" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </a>
                                        <a href="https://www.linkedin.com/company/gagangiriconstruction/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </a>
                                    </div>
                                </>
                            )}
                        </div>
                        {!mobileMenuOpen && (
                            <div className={`navbar-right animate`}>
                                <button className='contact-button' onClick={() => handleNavigation('/#contact-us')}><span>CONTACT US</span></button>
                                <div className='social-icons'>
                                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </a>
                                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faXTwitter} />
                                    </a>
                                    <a href="https://www.instagram.com/gagangirigroup/?igsh=N3ExMTFzN3B2Zjlh" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                    <a href="https://www.linkedin.com/company/gagangiriconstruction/" target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </>
                )}
                {animationComplete && (
                    <div className={`menu-icon ${mobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                )}
            </nav>
        </>
    );
}

export default NavBar;