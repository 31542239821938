import React from 'react';
import './CssStyles/LandingPage.css';

function LandingPage() {
    return (
        <div className="landing-page">
            <img src='/Images/Section1LogoBigNoBg.png' alt="Company Logo" className="logo" />
        </div>
    );
}

export default LandingPage;