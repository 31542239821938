import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './CssStyles/Footer.css';

function Footer() {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className='footer'>
            <div className='footer-complete'>
                <div className='footer-left'>
                    <img src='/Images/gagangiriFooter.png' alt='Company Logo' className='footer-logo' />
                </div>
                <div className='footer-right'>
                    <div className='footer-column'>
                        <h3>Quick Links</h3>
                        <ul>
                            <Link to='/'><li>Home</li></Link>
                            <Link to="/#company-profile" onClick={() => handleNavigation('/#company-profile')}><li>Company Profile</li></Link>
                            <Link to='/executive-profile'><li>Executive Profile</li></Link>
                            <Link to="/#our-philosophy" onClick={() => handleNavigation('/#our-philosophy')}><li>Our Philosophy</li></Link>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3>Projects</h3>
                        <ul>
                            <Link to="/#projects-overview" onClick={() => handleNavigation('/#projects-overview')}><li>Projects Overview</li></Link>
                            <Link to='/featured-projects'><li>All Projects</li></Link>
                            <Link to='/featured-projects?status=Ongoing'><li>Ongoing Projects</li></Link>
                            <Link to='/featured-projects?status=Completed'><li>Completed Projects</li></Link>
                            <Link to='/featured-projects?status=Upcoming'><li>Upcoming Projects</li></Link>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3>Connect</h3>
                        <div className='footer-social-icons'>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                            <a href="https://www.instagram.com/gagangirigroup/?igsh=N3ExMTFzN3B2Zjlh" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://www.linkedin.com/company/gagangiriconstruction/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                        <ul>
                            <li><a href="tel:+919987483377">+91 9987483377</a></li>
                            <li><a href="mailto:gagangirigroup@gagangiri.in">gagangirigroup@gagangiri.in</a></li>
                            <li>317, Ninad CHS Ltd, Bldg. No. 7, Kher Nagar Service Road, Bandra (E), Mumbai - 400051</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;