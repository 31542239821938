// ProjectCard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CssStyles/ProjectCard.css';

function ProjectCard({ project }) {
    const navigate = useNavigate();

    const handleViewProject = () => {
        navigate(`/project/${project['project-id']}`);
    };

    return (
        <div className="project-card-feature">
            <img 
                className="project-image-feature" 
                src={project['featured-projects-image']} 
                alt={project['project-name']} 
            />
            <div className="project-details-feature">
                <h3 className="project-title-feature">{project['project-name']}</h3>
                <div className="project-info-feature">
                    <p className="project-location-feature">{project.location} .</p>
                    <p className="project-status-feature">{project.status}</p>
                </div>
                <button className="view-project" onClick={handleViewProject}>View Project</button>
            </div>
        </div>
    );
}

export default ProjectCard;