import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faTimes, faFilter, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './CssStyles/FeaturedProjects.css';
import ProjectCard from './ProjectCard';
import Footer from './Footer';
import projects from './ProjectsJSON/project-information.json';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function FeaturedProjects() {
  const query = useQuery();
  const navigate = useNavigate();
  const [dropdowns, setDropdowns] = useState({
    residential: false,
    commercial: false,
    hospitality: false,
    socialInfrastructure: false,
  });

  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [hoveredDropdown, setHoveredDropdown] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    type: null,
    status: [],
    locations: [],
    configurations: [],
    priceRange: { start: null, end: null }
  });

  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [hoveredFilter, setHoveredFilter] = useState(null);
  const [additionalDropdownVisible, setAdditionalDropdownVisible] = useState(null);
  const filterDropdownTimeout = useRef(null);
  const additionalDropdownTimeout = useRef(null);

  const hoverTimeout = useRef(null);
  const [activeDropdownItem, setActiveDropdownItem] = useState(null); // New state to track active dropdown item

  const status = query.getAll('status');
  const type = query.get('type');
  const locations = query.getAll('location');
  const configurations = query.getAll('configuration');
  const priceStart = query.get('priceStart');
  const priceEnd = query.get('priceEnd');

  useEffect(() => {
    const newFilters = {
      status: status || [],
      type: type || null,
      locations: locations || [],
      configurations: configurations || [],
      priceRange: { start: priceStart || null, end: priceEnd || null }
    };

    // Only update state if filters have changed
    if (JSON.stringify(newFilters) !== JSON.stringify(selectedFilters)) {
      setSelectedFilters(newFilters);
    }
  }, [type, status, locations, configurations, priceStart, priceEnd, selectedFilters]);

  const toggleDropdown = (key) => {
    setDropdowns(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const getUniqueLocations = (type) => {
    const locations = projects
      .filter(project => !type || project.type === type)
      .map(project => project.location);
    return [...new Set(locations)];
  };

  const getUniqueConfigurations = () => {
    const configurations = projects
      .flatMap(project => project.configuration.split(',').map(config => config.trim()));
    return [...new Set(configurations)];
  };

  const getProjectsByLocation = (location) => {
    return projects.filter(project => project.location === location);
  };

  const handleFilterClick = (filterType, value) => {
    let newFilters;
    if (filterType === 'location') {
      const newLocations = selectedFilters.locations.includes(value)
        ? selectedFilters.locations.filter(loc => loc !== value)
        : [...selectedFilters.locations, value];
      newFilters = { ...selectedFilters, locations: newLocations };
      setSelectedFilters(newFilters);
      updateQueryParams(newFilters);
      setAdditionalDropdownVisible(null);
    } else if (filterType === 'status') {
      const newStatus = selectedFilters.status.includes(value)
        ? selectedFilters.status.filter(stat => stat !== value)
        : [...selectedFilters.status, value];
      newFilters = { ...selectedFilters, status: newStatus };
      setSelectedFilters(newFilters);
      updateQueryParams(newFilters);
      setAdditionalDropdownVisible(null);
    } else if (filterType === 'configuration') {
      const newConfigurations = selectedFilters.configurations.includes(value)
        ? selectedFilters.configurations.filter(config => config !== value)
        : [...selectedFilters.configurations, value];
      newFilters = { ...selectedFilters, configurations: newConfigurations };
      setSelectedFilters(newFilters);
      updateQueryParams(newFilters);
      setAdditionalDropdownVisible(null);
    } else if (filterType === 'priceRange') {
      newFilters = { ...selectedFilters, priceRange: { ...selectedFilters.priceRange, ...value } };
      setSelectedFilters(newFilters);
      updateQueryParams(newFilters);
    } else {
      newFilters = { ...selectedFilters, [filterType]: value };
      setSelectedFilters(newFilters);
      updateQueryParams(newFilters);
      setAdditionalDropdownVisible(null);
    }

    // Toggle hovered content for mobile devices
    if (filterType === 'location' || filterType === 'status' || filterType === 'configuration') {
      setActiveDropdownItem(prev => (prev === value ? null : value));
    }
  };

  const handleRemoveFilter = (filterType, value) => {
    let newFilters;
    if (filterType === 'location') {
      const newLocations = selectedFilters.locations.filter(loc => loc !== value);
      newFilters = { ...selectedFilters, locations: newLocations };
    } else if (filterType === 'status') {
      const newStatus = selectedFilters.status.filter(stat => stat !== value);
      newFilters = { ...selectedFilters, status: newStatus };
    } else if (filterType === 'configuration') {
      const newConfigurations = selectedFilters.configurations.filter(config => config !== value);
      newFilters = { ...selectedFilters, configurations: newConfigurations };
    } else if (filterType === 'priceRange') {
      newFilters = { ...selectedFilters, priceRange: { ...selectedFilters.priceRange, [value]: null } };
    } else {
      newFilters = { ...selectedFilters, [filterType]: null };
    }
    setSelectedFilters(newFilters);
    updateQueryParams(newFilters);
  };

  const handleClearAllFilters = () => {
    const newFilters = {
      status: [],
      type: null,
      locations: [],
      configurations: [],
      priceRange: { start: null, end: null }
    };
    setSelectedFilters(newFilters);
    navigate('/featured-projects'); // Clear query parameters
  };

  const handleMouseEnter = (location, dropdown) => {
    clearTimeout(hoverTimeout.current);
    setHoveredLocation(location);
    setHoveredDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setHoveredLocation(null);
      setHoveredDropdown(null);
    }, 200); // Adjust the delay as needed
  };

  const handleProjectClick = (event, projectId) => {
    event.stopPropagation();
    navigate(`/project/${projectId}`);
  };

  const updateQueryParams = (filters) => {
    const params = new URLSearchParams();
    Object.keys(filters).forEach(key => {
      if (key === 'locations') {
        filters[key].forEach(location => {
          params.append('location', location);
        });
      } else if (key === 'status') {
        filters[key].forEach(status => {
          params.append('status', status);
        });
      } else if (key === 'configurations') {
        filters[key].forEach(configuration => {
          params.append('configuration', configuration);
        });
      } else if (key === 'priceRange') {
        if (filters[key].start) params.set('priceStart', filters[key].start);
        if (filters[key].end) params.set('priceEnd', filters[key].end);
      } else if (filters[key]) {
        params.set(key, filters[key]);
      }
    });
    navigate(`/featured-projects?${params.toString()}`);
  };

  const handlePriceChange = (type, value) => {
    handleFilterClick('priceRange', { [type]: value });
  };

  const filteredProjects = projects.filter(project => {
    const projectConfigurations = project.configuration.split(',').map(config => config.trim());
    const projectPriceMatch = project.price.match(/₹([\d.]+) Cr/);
    const projectPrice = projectPriceMatch ? parseFloat(projectPriceMatch[1]) : null;
    const priceStart = selectedFilters.priceRange.start ? parseFloat(selectedFilters.priceRange.start) : null;
    const priceEnd = selectedFilters.priceRange.end ? parseFloat(selectedFilters.priceRange.end) : null;

    return (
      (!selectedFilters.type || project.type === selectedFilters.type) &&
      (selectedFilters.status.length === 0 || selectedFilters.status.includes(project.status)) &&
      (selectedFilters.locations.length === 0 || selectedFilters.locations.includes(project.location)) &&
      (selectedFilters.configurations.length === 0 || selectedFilters.configurations.some(config => projectConfigurations.includes(config))) &&
      (!priceStart || (projectPrice !== null && projectPrice >= priceStart)) &&
      (!priceEnd || (projectPrice !== null && projectPrice <= priceEnd))
    );
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-content') && !event.target.closest('.option')) {
        setDropdowns({
          residential: false,
          commercial: false,
          hospitality: false,
          socialInfrastructure: false,
        });
        setActiveDropdownItem(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="featured-projects">
      <div className="options">
        <div className="option" onClick={() => toggleDropdown('residential')}>
          <span>Residential</span>
          <FontAwesomeIcon icon={faAngleDown} className={`icon ${dropdowns.residential ? 'rotate' : ''}`} />
          {dropdowns.residential && (
            <div className="dropdown-content">
              {getUniqueLocations('residential').map((location, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(location, 'residential')}
                  onMouseLeave={handleMouseLeave}
                  className="dropdown-item"
                  onClick={() => handleFilterClick('location', location)}
                >
                  {location}
                  {((hoveredLocation === location && hoveredDropdown === 'residential') || activeDropdownItem === location) && (
                    <div
                      className="hover-content"
                      onMouseEnter={() => handleMouseEnter(location, 'residential')}
                      onMouseLeave={handleMouseLeave}
                    >
                      {getProjectsByLocation(location).map((project, idx) => (
                        <div key={idx} onClick={(event) => handleProjectClick(event, project['project-id'])}>
                          {project['project-name']}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="option" onClick={() => toggleDropdown('commercial')}>
          <span>Commercial</span>
          <FontAwesomeIcon icon={faAngleDown} className={`icon ${dropdowns.commercial ? 'rotate' : ''}`} />
          {dropdowns.commercial && (
            <div className="dropdown-content">
              {getUniqueLocations('commercial').map((location, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(location, 'commercial')}
                  onMouseLeave={handleMouseLeave}
                  className="dropdown-item"
                  onClick={() => handleFilterClick('location', location)}
                >
                  {location}
                  {((hoveredLocation === location && hoveredDropdown === 'commercial') || activeDropdownItem === location) && (
                    <div
                      className="hover-content"
                      onMouseEnter={() => handleMouseEnter(location, 'commercial')}
                      onMouseLeave={handleMouseLeave}
                    >
                      {getProjectsByLocation(location).map((project, idx) => (
                        <div key={idx} onClick={(event) => handleProjectClick(event, project['project-id'])}>
                          {project['project-name']}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="option" onClick={() => toggleDropdown('hospitality')}>
          <span>Hospitality</span>
          <FontAwesomeIcon icon={faAngleDown} className={`icon ${dropdowns.hospitality ? 'rotate' : ''}`} />
          {dropdowns.hospitality && (
            <div className="dropdown-content">
              {getUniqueLocations('hospitality').map((location, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(location, 'hospitality')}
                  onMouseLeave={handleMouseLeave}
                  className="dropdown-item"
                  onClick={() => handleFilterClick('location', location)}
                >
                  {location}
                  {((hoveredLocation === location && hoveredDropdown === 'hospitality') || activeDropdownItem === location) && (
                    <div
                      className="hover-content"
                      onMouseEnter={() => handleMouseEnter(location, 'hospitality')}
                      onMouseLeave={handleMouseLeave}
                    >
                      {getProjectsByLocation(location).map((project, idx) => (
                        <div key={idx} onClick={(event) => handleProjectClick(event, project['project-id'])}>
                          {project['project-name']}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="option" onClick={() => toggleDropdown('socialInfrastructure')}>
          <span>Education</span>
          <FontAwesomeIcon icon={faAngleDown} className={`icon ${dropdowns.socialInfrastructure ? 'rotate' : ''}`} />
          {dropdowns.socialInfrastructure && (
            <div className="dropdown-content">
              {getUniqueLocations('socialInfrastructure').map((location, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(location, 'socialInfrastructure')}
                  onMouseLeave={handleMouseLeave}
                  className="dropdown-item"
                  onClick={() => handleFilterClick('location', location)}
                >
                  {location}
                  {((hoveredLocation === location && hoveredDropdown === 'socialInfrastructure') || activeDropdownItem === location) && (
                    <div
                      className="hover-content"
                      onMouseEnter={() => handleMouseEnter(location, 'socialInfrastructure')}
                      onMouseLeave={handleMouseLeave}
                    >
                      {getProjectsByLocation(location).map((project, idx) => (
                        <div key={idx} onClick={(event) => handleProjectClick(event, project['project-id'])}>
                          {project['project-name']}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className='title-div'>
        <h1 className="title">Featured Projects</h1>
      </div>

      <div className="sort-filters">
        <div className="sort-option" onClick={() => handleFilterClick('status', 'Completed')}>
          <span>Completed Projects</span>
        </div>
        <div className="sort-option" onClick={() => handleFilterClick('status', 'Ongoing')}>
          <span>Ongoing Projects</span>
        </div>
        <div className="sort-option" onClick={() => handleFilterClick('status', 'Upcoming')}>
          <span>Upcoming Projects</span>
        </div>
        <div
          className="filter-icon"
          onMouseEnter={() => {
            clearTimeout(filterDropdownTimeout.current);
            setFilterDropdownVisible(true);
          }}
          onMouseLeave={() => {
            filterDropdownTimeout.current = setTimeout(() => {
              setFilterDropdownVisible(false);
            }, 200);
          }}
          onClick={() => setFilterDropdownVisible(!filterDropdownVisible)}
        >
          <FontAwesomeIcon icon={faFilter} />
          {filterDropdownVisible && (
            <div
              className="filter-dropdown"
              onMouseEnter={() => {
                clearTimeout(filterDropdownTimeout.current);
                setFilterDropdownVisible(true);
              }}
              onMouseLeave={() => {
                filterDropdownTimeout.current = setTimeout(() => {
                  setFilterDropdownVisible(false);
                }, 200);
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="dropdown-item"
                onMouseEnter={() => {
                  clearTimeout(additionalDropdownTimeout.current);
                  setHoveredFilter('location');
                  setAdditionalDropdownVisible('location');
                }}
                onMouseLeave={() => {
                  additionalDropdownTimeout.current = setTimeout(() => {
                    setAdditionalDropdownVisible(null);
                  }, 200);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} /> Location
                {additionalDropdownVisible === 'location' && (
                  <div
                    className="additional-dropdown"
                    onMouseEnter={() => {
                      clearTimeout(additionalDropdownTimeout.current);
                      setAdditionalDropdownVisible('location');
                    }}
                    onMouseLeave={() => {
                      additionalDropdownTimeout.current = setTimeout(() => {
                        setAdditionalDropdownVisible(null);
                      }, 200);
                    }}
                  >
                    {getUniqueLocations().map((location, index) => (
                      <div key={index} className="additional-dropdown-item">
                        <input
                          type="checkbox"
                          id={`location-${index}`}
                          checked={selectedFilters.locations.includes(location)}
                          onChange={() => handleFilterClick('location', location)}
                        />
                        <label htmlFor={`location-${index}`}>{location}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="dropdown-item"
                onMouseEnter={() => {
                  clearTimeout(additionalDropdownTimeout.current);
                  setHoveredFilter('configuration');
                  setAdditionalDropdownVisible('configuration');
                }}
                onMouseLeave={() => {
                  additionalDropdownTimeout.current = setTimeout(() => {
                    setAdditionalDropdownVisible(null);
                  }, 200);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} /> Configuration
                {additionalDropdownVisible === 'configuration' && (
                  <div
                    className="additional-dropdown"
                    onMouseEnter={() => {
                      clearTimeout(additionalDropdownTimeout.current);
                      setAdditionalDropdownVisible('configuration');
                    }}
                    onMouseLeave={() => {
                      additionalDropdownTimeout.current = setTimeout(() => {
                        setAdditionalDropdownVisible(null);
                      }, 200);
                    }}
                  >
                    {getUniqueConfigurations().map((configuration, index) => (
                      <div key={index} className="additional-dropdown-item">
                        <input
                          type="checkbox"
                          id={`configuration-${index}`}
                          checked={selectedFilters.configurations.includes(configuration)}
                          onChange={() => handleFilterClick('configuration', configuration)}
                        />
                        <label htmlFor={`configuration-${index}`}>{configuration}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                className="dropdown-item"
                onMouseEnter={() => {
                  clearTimeout(additionalDropdownTimeout.current);
                  setHoveredFilter('priceRange');
                  setAdditionalDropdownVisible('priceRange');
                }}
                onMouseLeave={() => {
                  additionalDropdownTimeout.current = setTimeout(() => {
                    setAdditionalDropdownVisible(null);
                  }, 200);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} /> Price Range
                {additionalDropdownVisible === 'priceRange' && (
                  <div
                    className="additional-dropdown price-range-dropdown"
                    onMouseEnter={() => {
                      clearTimeout(additionalDropdownTimeout.current);
                      setAdditionalDropdownVisible('priceRange');
                    }}
                    onMouseLeave={() => {
                      additionalDropdownTimeout.current = setTimeout(() => {
                        setAdditionalDropdownVisible(null);
                      }, 200);
                    }}
                  >
                    <div className="additional-dropdown-item">
                      <label htmlFor="price-start">Starting from ₹ Cr</label>
                      <input
                        type="number"
                        id="price-start"
                        className="price-input"
                        onChange={(e) => handlePriceChange('start', e.target.value)}
                      />
                    </div>
                    <div className="additional-dropdown-item">
                      <label htmlFor="price-end">Upto ₹ Cr</label>
                      <input
                        type="number"
                        id="price-end"
                        className="price-input"
                        onChange={(e) => handlePriceChange('end', e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {(Object.values(selectedFilters).some(filter => filter && filter.length > 0) || selectedFilters.priceRange.start || selectedFilters.priceRange.end) && (
        <div className="selected-filters active">
          {Object.keys(selectedFilters).map(filterType => (
            filterType === 'locations' ? (
              selectedFilters[filterType].map(location => (
                <div key={location} className="selected-filter">
                  {location}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="remove-icon"
                    onClick={() => handleRemoveFilter('location', location)}
                  />
                </div>
              ))
            ) : filterType === 'status' ? (
              selectedFilters[filterType].map(status => (
                <div key={status} className="selected-filter">
                  {status}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="remove-icon"
                    onClick={() => handleRemoveFilter('status', status)}
                  />
                </div>
              ))
            ) : filterType === 'configurations' ? (
              selectedFilters[filterType].map(configuration => (
                <div key={configuration} className="selected-filter">
                  {configuration}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="remove-icon"
                    onClick={() => handleRemoveFilter('configuration', configuration)}
                  />
                </div>
              ))
            ) : filterType === 'priceRange' ? (
              <>
                {selectedFilters.priceRange.start && (
                  <div key="price-start" className="selected-filter">
                    Starting from ₹{selectedFilters.priceRange.start} Cr
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={() => handleRemoveFilter('priceRange', 'start')}
                    />
                  </div>
                )}
                {selectedFilters.priceRange.end && (
                  <div key="price-end" className="selected-filter">
                    Upto ₹{selectedFilters.priceRange.end} Cr
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="remove-icon"
                      onClick={() => handleRemoveFilter('priceRange', 'end')}
                    />
                  </div>
                )}
              </>
            ) : (
              selectedFilters[filterType] && (
                <div key={filterType} className="selected-filter">
                  {selectedFilters[filterType]}
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="remove-icon"
                    onClick={() => handleRemoveFilter(filterType)}
                  />
                </div>
              )
            )
          ))}
          {(Object.values(selectedFilters).some(filter => filter && filter.length > 0) || selectedFilters.priceRange.start || selectedFilters.priceRange.end) && (
            <div className="clear-filters" onClick={handleClearAllFilters}>
              Clear All
            </div>
          )}
        </div>
      )}

      <div className="project-cards">
        {filteredProjects.map(project => (
          <ProjectCard key={project['project-id']} project={project} />
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default FeaturedProjects;